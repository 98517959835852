import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Login from './Components/Login/Login';
import SignUp from './Components/SignUp/SignUp';

import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <div>
            <button
                className="btn"
                onClick={() => {
                    window.location.href = '/login';
                }
                }>
                Login
            </button>
            <button
                className="btn"
                onClick={() => {
                    window.location.href = '/signup';
                }
                }>
                Sign Up
            </button>
        </div>,
    },
    {
        path: "/login",
        element: Login()
    },
    {
        path: "/signup",
        element: SignUp()
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
